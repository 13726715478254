import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
    Action,
    NgxsOnInit,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { uid } from 'uid';

import {
    AddShareListAction,
    ChangeActiveShareListAction,
    CloseShareListAction,
} from '~/app/core/state/shares-list/shares-list.actions';
import {
    SharesListState,
    SharesListStateModel,
} from '~/app/core/state/shares-list/shares-list.state';
import { Workspace } from '~/app/shared/types/workspace.type';

import {
    AddWorkspaceAction,
    ChangeActiveWorkspaceAction,
    CloseWorkspaceAction,
    ResetSharesWorkspaceAction,
} from './shares-workspace.actions';

export interface SharesWorkspaceStateModel {
    currentWorkspace: string,
    workspaces: { [key: string]: Workspace },
}

const defaults = {
    currentWorkspace: '',
    workspaces: {},
};

@State<SharesWorkspaceStateModel>({
    name: 'sharesWorkspace',
    defaults,
})
@Injectable()
export class SharesWorkspaceState implements NgxsOnInit {
    constructor(
        private transloco: TranslocoService,
    ) {
    }

    @Selector([SharesListState])
    static workspaces(state: SharesWorkspaceStateModel, listState: SharesListStateModel) {
        return Object.values(state.workspaces)
            .map((item) => ({
                ...item,
                number: listState.sharesList[item.key].filtersCount,
            }));
    }

    @Selector()
    static currentWorkspace(state: SharesWorkspaceStateModel) {
        return state.currentWorkspace;
    }

    @Selector()
    static workspacesCount(state: SharesWorkspaceStateModel): number {
        return Object.keys(state.workspaces).length;
    }

    @Action(AddWorkspaceAction)
    addWorkspace({ getState, patchState, dispatch }: StateContext<SharesWorkspaceStateModel>, action: AddWorkspaceAction) {
        const state = getState();
        const key: string = uid();
        const workspaceName$ = action.name ? of(action.name) : of('workspace.default');

        return workspaceName$.pipe(
            tap((name: string) => {
                patchState({
                    currentWorkspace: key,
                    workspaces: {
                        ...state.workspaces,
                        [key]: {
                            key,
                            label: name,
                            translatable: !!action.name,
                        },
                    },
                });

                dispatch(new AddShareListAction(key));
            }),
        );
    }

    @Action(ChangeActiveWorkspaceAction)
    changeActiveWorkspace({ getState, patchState, dispatch }: StateContext<SharesWorkspaceStateModel>, action: ChangeActiveWorkspaceAction) {
        const state = getState();

        if (!state.workspaces[action.key]) { return; }

        patchState({ currentWorkspace: action.key });

        dispatch(new ChangeActiveShareListAction(action.key));
    }

    @Action(CloseWorkspaceAction)
    closeWorkspace({ getState, patchState, dispatch }: StateContext<SharesWorkspaceStateModel>, action: CloseWorkspaceAction) {
        const state = getState();

        if (!state.workspaces[action.key]) { return; }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [action.key]: workspace, ...rest } = state.workspaces;

        let { currentWorkspace } = state;

        if (state.currentWorkspace === action.key) {
            const index = Object.keys(state.workspaces).indexOf(action.key);

            if (index === Object.keys(state.workspaces).length - 1) {
                currentWorkspace = Object.keys(state.workspaces)[Object.keys(state.workspaces).length - 2];
            } else {
                currentWorkspace = Object.keys(state.workspaces)[index + 1];
            }

            if (Object.keys(rest).length > 0) {
                dispatch(new ChangeActiveWorkspaceAction(currentWorkspace));
            }
        }

        patchState({ workspaces: { ...rest } });

        dispatch(new CloseShareListAction(action.key));

        // Add new workspace
        if (Object.keys(rest).length === 0) {
            dispatch(new AddWorkspaceAction());
        }
    }

    @Action(ResetSharesWorkspaceAction)
    resetSharesWorkspace({ getState, dispatch }: StateContext<SharesWorkspaceStateModel>) {
        const state = getState();
        Object.keys(state.workspaces).forEach((key) => {
            dispatch(new CloseWorkspaceAction(key));
        });
    }

    ngxsOnInit({ getState, dispatch }: StateContext<SharesWorkspaceStateModel>) {
        const state = getState();

        if (Object.keys(state.workspaces).length === 0) {
            dispatch(new AddWorkspaceAction());
        }
    }
}
